nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background-color: #1a2741;
	padding: 20px 20px;
}

nav > div {
	margin: auto;
	padding: 0px;
}

nav #nav-logo h1 {
	margin: 0px;
}

nav #nav-toggle {
	height: 24px;
}

nav #nav-toggle-button {
	cursor: pointer;
}

nav #nav-links {
	max-height: 0px;
	transition: max-height 0.25s ease-in-out;
	overflow: hidden;
}

nav #nav-links ul {
	display: block;
	list-style: none;
	position: relative;
	top: 50%;
	padding: 20px 0px 0px 0px;
	margin: 0px;
}

nav #nav-links li {
	display: block;
	padding: 5px 0px;
}

nav .nav-left {
	flex-grow: 1;
}

nav .nav-right {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 100%;
}

nav .nav-left a {
	color: var(--foreground_text_color);
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
}

.big {
	font-size: 8vw;
}

.bigish {
	font-size: 6vw;
}

.content {
	padding: 10px;
}

@media only screen and (min-aspect-ratio: 3/4) {
	nav #nav-links {
		display: block !important;
		max-height: unset;
		flex-grow: 2;
		flex-shrink: unset;
		flex-basis: unset;
		transition: max-height none;
	}
	nav #nav-links ul {
		display: inline-block;
		padding: 0px 0px 0px 0px;
	}
	nav #nav-links li {
		display: inline;
		padding: 0px 5px;
	}
	.nav-right {
		text-align: right;
	}
	nav #nav-toggle {
		display: none;
	}
	.big {
		font-size: 6vh;
	}
	.bigish {
		font-size: 5vh;
	}
}

nav .nav-right a {
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
	transition: color 0.25s ease-in-out;
	color: var(--foreground_text_color);
}

nav a.active {
	color: var(--foreground_text_color);
}

nav a:hover {
	color: var(--foreground_text_color);
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-family: sans-serif;
}

.wildkit {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 80vw;
}

.none {
	display: none;
}

.block {
	display: block;
}

.text-center {
	text-align: center;
}

.absolute.text-center {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.float.left {
	float: left;
}

.float.right {
	float: right;
}

@-webkit-keyframes fade_in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade_in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes fade_out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes fade_out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.fadein {
	-webkit-animation: 0.25s ease-in-out 1 fade_in;
	animation: 0.25s ease-in-out 1 fade_in;
	opacity: 1;
}

.fadeout {
	-webkit-animation: 0.5s ease-in-out 1 fade_out;
	animation: 0.5s ease-in-out 1 fade_out;
	opacity: 0;
}

.hidecursor {
	cursor: none;
}

nav.abs {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 60;
}

@-webkit-keyframes slide_out_top {
	from {
		top: 0;
	}
	to {
		top: -30vh;
	}
}

@keyframes slide_out_top {
	from {
		top: 0;
	}
	to {
		top: -30vh;
	}
}

@-webkit-keyframes slide_in_top {
	from {
		top: -30vh;
	}
	to {
		top: 0;
	}
}

@keyframes slide_in_top {
	from {
		top: -30vh;
	}
	to {
		top: 0;
	}
}

nav.abs.shown {
	-webkit-animation-name: slide_in_top;
	animation-name: slide_in_top;
	-webkit-animation-duration: 0.25s;
	animation-duration: 0.25s;
	top: 0;
}

nav.abs.hidden {
	-webkit-animation-name: slide_out_top;
	animation-name: slide_out_top;
	-webkit-animation-duration: 0.25s;
	animation-duration: 0.25s;
	top: -30vh;
}

body {
	--foreground_color: #1a2741;
	--background_color: #c34614;
	--foreground_text_color: #ffffff;
	--background_text_color: #ffffff;
	overscroll-behavior-x: none;
}

.foreground-color {
	background-color: var(--foreground_color);
	color: var(--foreground_text_color);
}

.background-color {
	background-color: var(--background_color);
	color: var(--background_text_color);
}

a {
	color: inherit;
}

.divider {
	width: 75px;
	height: 3px;
	background-color: var(--background_text_color);
	margin: 10px auto;
}

.progress-ring__circle {
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
}

#fullscreen {
	display: none;
	position: absolute;
	right: 25px;
	bottom: 25px;
	top: unset;
	cursor: pointer;
	outline: 0;
}

.advisory:not(:empty) {
	display: inline-block;
	position: fixed;
	bottom: 10px;
	left: 10px;
	background-color: red;
	padding: 10px;
	border-radius: 10px;
	color: white;
	font-weight: bold;
	opacity: 0.5;
}

.advisory-close {
	margin-left: 5px;
	background-color: transparent;
	border: none;
	color: white;
	cursor: pointer;
	font-size: 150%;
	vertical-align: middle;
}

nav {
  background-color: #1a2741;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  display: flex;
}

nav > div {
  margin: auto;
  padding: 0;
}

nav #nav-logo h1 {
  margin: 0;
}

nav #nav-toggle {
  height: 24px;
}

nav #nav-toggle-button {
  cursor: pointer;
}

nav #nav-links {
  max-height: 0;
  transition: max-height .25s ease-in-out;
  overflow: hidden;
}

nav #nav-links ul {
  margin: 0;
  padding: 20px 0 0;
  list-style: none;
  display: block;
  position: relative;
  top: 50%;
}

nav #nav-links li {
  padding: 5px 0;
  display: block;
}

nav .nav-left {
  flex-grow: 1;
}

nav .nav-right {
  flex: 0 0 100%;
}

nav .nav-left a {
  color: var(--foreground_text_color);
  text-decoration-line: none;
}

.big {
  font-size: 8vw;
}

.bigish {
  font-size: 6vw;
}

.content {
  padding: 10px;
}

@media only screen and (min-aspect-ratio: 3 / 4) {
  nav #nav-links {
    max-height: unset;
    flex-grow: 2;
    flex-shrink: unset;
    flex-basis: unset;
    transition: max-height none;
    display: block !important;
  }

  nav #nav-links ul {
    padding: 0;
    display: inline-block;
  }

  nav #nav-links li {
    padding: 0 5px;
    display: inline;
  }

  .nav-right {
    text-align: right;
  }

  nav #nav-toggle {
    display: none;
  }

  .big {
    font-size: 6vh;
  }

  .bigish {
    font-size: 5vh;
  }
}

nav .nav-right a {
  color: var(--foreground_text_color);
  text-decoration-line: none;
  transition: color .25s ease-in-out;
}

nav a.active, nav a:hover {
  color: var(--foreground_text_color);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
}

.wildkit {
  height: 80vw;
  position: absolute;
  bottom: 0;
  left: 0;
}

.none {
  display: none;
}

.block {
  display: block;
}

.text-center {
  text-align: center;
}

.absolute.text-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.float.left {
  float: left;
}

.float.right {
  float: right;
}

@keyframes fade_in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade_out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadein {
  opacity: 1;
  animation: .25s ease-in-out fade_in;
}

.fadeout {
  opacity: 0;
  animation: .5s ease-in-out fade_out;
}

.hidecursor {
  cursor: none;
}

nav.abs {
  z-index: 60;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@keyframes slide_out_top {
  from {
    top: 0;
  }

  to {
    top: -30vh;
  }
}

@keyframes slide_in_top {
  from {
    top: -30vh;
  }

  to {
    top: 0;
  }
}

nav.abs.shown {
  animation-name: slide_in_top;
  animation-duration: .25s;
  top: 0;
}

nav.abs.hidden {
  animation-name: slide_out_top;
  animation-duration: .25s;
  top: -30vh;
}

body {
  --foreground_color: #1a2741;
  --background_color: #c34614;
  --foreground_text_color: #fff;
  --background_text_color: #fff;
  overscroll-behavior-x: none;
}

.foreground-color {
  background-color: var(--foreground_color);
  color: var(--foreground_text_color);
}

.background-color {
  background-color: var(--background_color);
  color: var(--background_text_color);
}

a {
  color: inherit;
}

.divider {
  background-color: var(--background_text_color);
  width: 75px;
  height: 3px;
  margin: 10px auto;
}

.progress-ring__circle {
  transform-origin: 50%;
  transform: rotate(-90deg);
}

#fullscreen {
  bottom: 25px;
  right: 25px;
  top: unset;
  cursor: pointer;
  outline: 0;
  display: none;
  position: absolute;
}

.advisory:not(:empty) {
  color: #fff;
  opacity: .5;
  background-color: red;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  display: inline-block;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.advisory-close {
  color: #fff;
  cursor: pointer;
  vertical-align: middle;
  background-color: #0000;
  border: none;
  margin-left: 5px;
  font-size: 150%;
}

/*# sourceMappingURL=index.9ed35dce.css.map */
